//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import fullList from '../../assets/src/locations.json'
import mapStyles from '~/assets/src/map-styles.json'

export default {
  props: {
    content: '',
    searchable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      locations: [],
      activeState: null,
      mapActive: false,
      window_open: '',
      activeMarker: '',
      infoContent: {
        title: '',
        address: '',
        url: '',
        menu_id: null,
        id: null,
        services: {},
      },
      center: { lat: 39.828432392969795, lng: -98.57946897325819 },
      zoom: 5,
      markers: [],
      clusterStyles: [
        {
          height: 53,
          url: 'https://a.storyblok.com/f/99717/x/a42066d3cc/cluster-green.svg',
          width: 53,
        },
        {
          height: 56,
          url: 'https://a.storyblok.com/f/99717/x/a42066d3cc/cluster-green.svg',
          width: 56,
        },
        {
          height: 66,
          url: 'https://a.storyblok.com/f/99717/x/a42066d3cc/cluster-green.svg',
          width: 66,
        },
        {
          height: 78,
          url: 'https://a.storyblok.com/f/99717/x/a42066d3cc/cluster-green.svg',
          width: 78,
        },
        {
          height: 90,
          url: 'https://a.storyblok.com/f/99717/x/a42066d3cc/cluster-green.svg',
          width: 90,
        },
      ],
      options: {
        styles: mapStyles,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
      },
      us: true,
      showList: false,
      searched: false,
      searching: false,
      searchTerm: null,
      locations: [],
      allLocations: [],
      states: [
        ['AL', 'Alabama'],
        ['AK', 'Alaska'],
        ['AZ', 'Arizona'],
        ['AR', 'Arkansas'],
        ['CA', 'California'],
        ['CO', 'Colorado'],
        ['CT', 'Connecticut'],
        ['DE', 'Delaware'],
        ['FL', 'Florida'],
        ['GA', 'Georgia'],
        ['HI', 'Hawaii'],
        ['ID', 'Idaho'],
        ['IL', 'Illinois'],
        ['IN', 'Indiana'],
        ['IA', 'Iowa'],
        ['KS', 'Kansas'],
        ['KY', 'Kentucky'],
        ['LA', 'Louisiana'],
        ['ME', 'Maine'],
        ['MD', 'Maryland'],
        ['MA', 'Massachusetts'],
        ['MI', 'Michigan'],
        ['MN', 'Minnesota'],
        ['MS', 'Mississippi'],
        ['MO', 'Missouri'],
        ['MT', 'Montana'],
        ['NE', 'Nebraska'],
        ['NV', 'Nevada'],
        ['NH', 'New Hampshire'],
        ['NJ', 'New Jersey'],
        ['NM', 'New Mexico'],
        ['NY', 'New York'],
        ['NC', 'North Carolina'],
        ['ND', 'North Dakota'],
        ['OH', 'Ohio'],
        ['OK', 'Oklahoma'],
        ['OR', 'Oregon'],
        ['PA', 'Pennsylvania'],
        ['RI', 'Rhode Island'],
        ['SC', 'South Carolina'],
        ['SD', 'South Dakota'],
        ['TN', 'Tennessee'],
        ['TX', 'Texas'],
        ['UT', 'Utah'],
        ['VT', 'Vermont'],
        ['VA', 'Virginia'],
        ['WA', 'Washington'],
        ['WV', 'West Virginia'],
        ['WI', 'Wisconsin'],
        ['WY', 'Wyoming'],
      ],
    }
  },
  computed: {
    scrollToReveal() {
      return this.content && this.content.scroll_to_reveal
    },
    fullList() {
      return this.$store.state.locations
    },
  },
  mounted() {
    this.default()
  },
  
  methods: {
    handoffText(value) {
      switch (value) {
        case 'pickup':
          return 'pickup'
        case 'curbside':
          return 'curbside'
        default:
          return 'delivery'
      }
    },
    revealMap(el) {
      if (this.scrollToReveal) {
        this.mapActive = true
        this.$nextTick(() => {
          this.loadListeners()
        })
      }
    },
    loadMap() {
      console.log('rending map')
      if (!this.scrollToReveal) {
        this.mapActive = true
        this.$nextTick(() => {
          this.loadListeners()
        })
      }
    },
    loadListeners() {
      try {
        this.$refs.locationsMap.$mapPromise.then((map) => {
          // map.panTo({lat: 1.38, lng: 103.80})
          map.addListener('dragend', () => {
            this.window_open = false
          })
          map.addListener('zoom_changed', () => {
            // this.window_open = false
          })
          map.addListener('click', () => {
            // map.panTo({lat: this.center.lat, lng: this.center.lat})
          })
        })
      } catch (err) {
        // not sure what to do here
      }
    },
    toggleUS(us) {
      if (!this.mapActive) this.loadMap()
      this.us = us
      if (us) {
        this.center = { lat: 39.828432392969795, lng: -98.57946897325819 }
      } else {
        this.center = { lat: 48.139910958108736, lng: -97.00450135066569 }
      }
    },
    showState(i) {
      if (this.activeState == i) {
        this.activeState = null
      } else {
        this.activeState = i
      }
    },
    async search() {
      try {
        this.$store.commit('setLoading', true)
        this.searching = true
        const search = await this.$api.findLocations({
          address: this.searchTerm,
        })
        console.log('test', search)
        this.locations = this.purgeLocations(search.locations)
        this.center = { lat: search.position.lat, lng: search.position.lng }
        this.searched = true
        this.searching = false
        this.zoom = 6
        if (search) this.$store.commit('setLoading', false)
      } catch (error) {
        this.$store.commit('setLoading', false)
      }
    },
    geosearch() {
      if (!this.mapActive) this.loadMap()
      if (navigator.geolocation) {
        this.$store.commit('setLoading', true)
        this.searching = true
        navigator.geolocation.getCurrentPosition(
          this.getCoordinates,
          this.getCoordinatesError
        )
      } else {
        this.$store.commit('setActiveErrors', [
          'Please enable location to use this feature.',
        ])
      }
    },
    async getCoordinates(position) {
      let coords = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      }
      let search = await this.$api.findLocations(coords)
      this.locations = this.purgeLocations(search.locations)
      this.searched = true
      this.searching = false
      this.center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      }
      this.zoom = 6
      this.$store.commit('setLoading', false)
    },
    getCoordinatesError() {
      this.$store.commit('setLoading', false)
      this.searching = false
      this.$store.commit('setActiveErrors', [
        'Please enable location to use this feature.',
      ])
    },
    purgeLocations(locs) {
      let locations = []
      locs.map((l) => {
        if (l.closed != true) {
          locations.push(l)
        }
      })
      return locations
    },
    async default() {
      this.allLocations = JSON.parse(JSON.stringify(this.fullList)) //await this.$storyblok.getAllPages('locations')
      this.allLocations.sort((a, b) => {
        return a.content.name < b.content.name ? -1 : 1
      })
      this.locations = this.allLocations
      // this.locations = await this.$storyblok.getPages('locations')
    },
    async viewMenu(l, s) {
      this.$store.commit('setLoading', true)
      let location = await this.$api.getLocation(l.id)
      if (location) {
        this.$store.commit('setLocation', location)
        this.startOrder(location, s)
      } else {
        this.$store.commit('setLoading', false)
      }
    },
    async startOrder(l, s) {
      this.$store.commit('setLocation', l)
      this.$store.commit('setLoading', true)
      let menu = await this.$api.getMenu(l.menu_id)
      this.$store.commit('setLoading', false)
      if (menu) {
        this.$store.commit('setActiveMenu', menu)
        this.$store.commit('setActiveOrder', null)

          if(s){
            await this.$api.createOrder(l.menu_id).then(order => {
                const customOrder = order   
                    customOrder.handoff = s
                this.$store.commit('setActiveOrder', customOrder) 
                this.$router.push(`/order/`)
            }) 
          }else{
            this.$router.push(`/menu/`)
          } 
      } else {
        // this.$store.commit('setActiveErrors', ['Connection issue. Please try again'])
      }
    },
    hasLocations(state) {
      let i = 0
      for (i = 0; i < this.allLocations.length; i++) {
        // console.log(this.locations[i].content.state)
        if (this.allLocations[i].content.state === state) {
          return true
        }
      }
      return false
    },
    async handleClick(item, i) {
      this.zoom = 14
      this.activeMarker = i
      this.window_open = i
      if (item.content) {
        this.center = {
          lat: item.content.latitude,
          lng: item.content.longitude,
        }
      } else {
        this.center = { lat: item.lat, lng: item.lng }
      }

      if (item.content) {
        this.infoContent.address = item.content.address
        this.infoContent.title = item.content.name
        this.infoContent.menu_id = item.content.menu_id
        this.infoContent.id = item.content.id
      } else {
        this.infoContent.address = item.address
        this.infoContent.title = item.name
        this.infoContent.menu_id = item.menu_id
        this.infoContent.id = item.id
      }

      if (item.path) {
        this.infoContent.url = item.path.match(/^locations/)
          ? item.path
          : `locations/${l.country.toLowerCase()}/${item.path}`
      } else {
        let slug = item.address
          .toLowerCase()
          .replace(/\s/g, '-')
          .replace(/\./g, '-')
          .replace(/\'/g, '-')
          .replace(/\//g, '-')
          .replace(/\-+$/, '')
        let city = item.city
          .toLowerCase()
          .replace(/\s/g, '-')
          .replace(/\./g, '-')
        let url = `${item.country.toLowerCase()}/${item.state.toLowerCase()}/${city}/${slug}`
        this.infoContent.url = url
      }

      try {
        const location = await this.$api.getLocation(
          item.content ? item.content.id : item.id
        ) 
        const asArray = Object.entries(location.services)
        const usedServices = asArray.filter(([key, value]) => value === true) 
        this.infoContent.services = Object.fromEntries(usedServices)
      } catch (error) {
        console.log(error)
      }
    },
  },
}
